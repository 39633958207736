import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentication from '@/components/pages/Authentication/Authentication'
import * as Auth from '@/components/pages/Authentication'
import Home from '@/components/pages/Home'
import Catalog from '@/components/pages/Catalog'
import Contacts from '@/components/pages/Contacts'
import About from '@/components/pages/About'
import Contract from '@/components/pages/Contract'
import Product from '@/components/pages/Product'


import AdminCarbodies from '@/components/pages/Admin/Carbodies'
import Users from '@/components/pages/Admin/Users'
import AdminContacts from '@/components/pages/Admin/Contacts'
import AdminAbout from '@/components/pages/Admin/About'
import AdminContract from '@/components/pages/Admin/Contract'
import AdminSettings from '@/components/pages/Admin/Settings'
import AdminProducts from '@/components/pages/Admin/Products'
import AdminCategories from '@/components/pages/Admin/Categories'
import AdminProduct from '@/components/pages/Admin/Product'
import AdminStockProducts from '@/components/pages/Admin/StockProducts'
import AdminStockProduct from '@/components/pages/Admin/StockProduct'
import AdminSubscribers from '@/components/pages/Admin/Subscribers'

import Stock from '@/components/pages/Stock'






Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'БРЕНДЫ',
        components: {
            default: Home
        },
        meta: {
            header: 'default',            
            showmobilemenu: true,
        }
    },
    {
        path: '/products',
        name: 'КАТАЛОГ',
        components: {
            default: Catalog
        },
        meta: {
            header: 'default',
            headerbgcolor: true,
            showmobilemenu: true,
            showmenu: true,
        }
    },
    {
        path: '/products/:id',
        name: 'Бренд',
        components: {
            default: Catalog
        },
        meta: {
            header: 'default',
            headerbgcolor: true,
            showmobilemenu: false,
            showmenu: false,
        }
    },
    {
        path: '/stockproducts',
        name: 'В НАЛИЧИИ',
        components: {
            default: Stock
        },
        meta: {
            header: 'default',
            headerbgcolor: true,
            showmobilemenu: true,
            showmenu: true,
        }
    },
    {
        path: '/stockproducts/:id',
        name: 'Авто в наличии',
        components: {
            default: Stock
        },
        meta: {
            header: 'default',
            headerbgcolor: true,
            showmobilemenu: false,
            showmenu: false,
        }
    },   
    {
        path: '/contacts',
        name: 'МЕНЕДЖЕРЫ',        
        components: {
            default: Contacts
        },
        meta: {
            header: 'default',
            headerbgcolor: true,
            showmenu: true,
            showmobilemenu: true,
        }
    },
    {
        path: '/about',        
        name: 'О НАС',        
        components: {
            default: About
        },
        meta: {
            header: 'default',
            headerbgcolor: true,
            showmenu: true,
            showmobilemenu: true,
        }
    },
    {
        path: '/contract',
        name: 'УСЛОВИЯ',        
        components: {
            default: Contract
        },
        meta: {
            header: 'default',
            headerbgcolor: true,
            showmenu: true,
            showmobilemenu: true,
        }
    },
    {
        path: '/product/:id',
        name: 'Товар',       
        components: {
            default: Product
        },
        meta: {
            header: 'default',
            headerbgcolor: true,
            showmenu: false,
        }
    },
    {
        path: '/admin/users',
        name: 'Пользователи',
        header: 'admin',        
        components: {
            default: Users
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },
    {
        path: '/admin/categories',
        name: 'Бренды',
        header: 'admin',        
        components: {
            default: AdminCategories
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },
    {
        path: '/admin/carbodies',
        name: 'Кузовы',
        header: 'admin',        
        components: {
            default: AdminCarbodies
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },
    {
        path: '/admin/products',
        name: 'Каталог',
        header: 'admin',        
        components: {
            default: AdminProducts
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },
    {
        path: '/admin/stockproducts',
        name: 'В наличии',
        header: 'admin',        
        components: {
            default: AdminStockProducts
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },
    {
        path: '/admin/Contacts',
        name: 'Менеджеры',        
        components: {
            default: AdminContacts
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },
    {
        path: '/admin/about',
        name: 'О нас ',        
        components: {
            default: AdminAbout
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },
    {
        path: '/admin/Contract',
        name: 'Условия ',        
        components: {
            default: AdminContract
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },
    {
        path: '/admin/subscribers',
        name: 'Рассылка',
        header: 'admin',        
        components: {
            default: AdminSubscribers
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },  
    {
        path: '/admin/settings',
        name: 'Настройки',
        header: 'admin',        
        components: {
            default: AdminSettings
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: true,
            admin: true
        }
    },    
    {
        path: '/admin/product/:id',
        name: 'Изменение товара',
        header: 'admin',        
        components: {
            default: AdminProduct
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: false,
            admin: true
        }
    },
    {
        path: '/admin/stockproduct/:id',
        name: 'Изменение авто в наличии',
        header: 'admin',        
        components: {
            default: AdminStockProduct
        },
        meta: {
            requiredAuth: true,
            header: 'admin',
            showmenu: false,
            admin: true
        }
    },
    {
        path: '/admin',
        name: 'Authentication',
        component: Authentication,
        meta: {
            admin: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
//перед переходом проверка на необходимость авторизации
router.beforeEach((to, from, next) => {
    if (to.meta.requiredAuth) {
        if (Auth.default.user.authenticated) {
            next()
        } else {
            router.push('/admin')
        }
    } else {
        next()
    }
})


export default router
