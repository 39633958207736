<template>
  <div>
    <main-header :settings="settings"></main-header>       
        <b-container class="mt-5 mb-2">   
          <h2 class="text-center mb-5" v-if="settings"><b> <span v-html="settings.brandcaption"></span></b></h2>
          <div class="logo-categories shadow-green" >  
      <b-row align-h="around" no-gutters>
        <b-col
          cols="6"
          md="4"
          lg="3"
          class="mb-3 pb-5 pt-3 category-block"
          v-for="category in categories"
          :key="category.id"
        >
        <a :href="'/products/'+category.id" class="d-flex align-items-center h-100 px-2 px-md-5">
          <img :src="'/'+category.logo.path" width="100%" loading="lazy"/>
        </a>   
        <div class="logo-text text-center position-absolute bottom w-100">
          {{category.name}}
        </div>     
        </b-col>
      </b-row> 
    </div>        
       
      </b-container>  
      <mail-form-discount class="my-5"></mail-form-discount> 
      <main-slider :settings="settings"></main-slider>
         
      
  </div>
</template>
<script>
import MainHeader from '@/components/components/MainHeader'
import MainSilder from '@/components/components/MainSilder'
import Authentication from "@/components/pages/Authentication";
import MailFormDiscount from "@/components/components/MailFormDiscount";
export default {
  props:['settings'],
    data() {
    return {
      categories:[], 
    };
  },
    components:{        
        'main-slider': MainSilder,
        'main-header': MainHeader,
        'mail-form-discount': MailFormDiscount,

    },
  created() {
    this.getAllcategories();
  },
  methods: {  
    async getAllcategories() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/categories",
        "get"
      );
      if (data) {
        this.categories = data;        
      }
    },
  },
};
</script>
<style lang="scss">    
.logo-categories{
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 8%; 
  padding-right: 8%;
  img{
    transition: 0.3s;
    &:hover{
      transform: scale(1.15);
    }
  }
}

.logo-text{
  font-weight: 600;
  font-size: 24px;
}

.category-block:hover{
box-shadow: rgb(37, 211, 102) 0px 0px 8px;
}

</style>