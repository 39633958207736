<template>
  <div class="page-admin-block">
    <b-container class="py-4">
      <b-button @click="$router.push('/admin/products')" variant="outline-secondary" class="mb-3">
        В каталог
      </b-button>
      <h3  v-if="product">Редактировать {{product.name}}</h3>
      <hr>
      <b-form v-if="product" class="ml-3">
        <b-row>
          <b-col cols="12" md="7">
            <b-form-group label="Название">
          <b-form-input
            id="product_name"
            type="text"
            label="Name"
            v-model="product.name"
            required
            placeholder="Название"
          ></b-form-input>
        </b-form-group>
          </b-col>
          <b-col cols="12" md="5">
            <b-form-group label="Бренд">
          <b-form-select v-model="product.category_id" :options="categories"></b-form-select>
        </b-form-group>
          </b-col>
        </b-row>               
        <b-form-group label="Описание">
          <editor v-model="product.description"></editor>
        </b-form-group>
        <b-form-group label="Полное описание">
          <editor v-model="product.full_description"></editor>                    
        </b-form-group>   
        <b-form-group label="Ссылка на салон">
          <b-form-input
            type="text"
            label="Link_frame"
            v-model="product.link_frame"
            required
            placeholder="Ссылка на салон"
          ></b-form-input>
        </b-form-group>    
        <!--<b-form-group label="Описание на главной странице">
          <editor v-model="product.description_main_page"></editor>                    
        </b-form-group>-->
        <!--<b-form-checkbox v-model="product.main_page">
          Разместить на главной странице
        </b-form-checkbox>-->
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Порядковый номер">
          <b-form-input
            id="product_field_sort"
            type="number"
            min=1
            :max="max_field_sort"
            label="Field_sort"
            v-model="product.field_sort"
            placeholder="Порядковый номер"
          ></b-form-input>
        </b-form-group>
          </b-col>
        </b-row>             
        <b-form-checkbox v-model="product.public">
          Опубликовать
        </b-form-checkbox>        

        <b-button
          class="my-3"
          variant="secondary"
          @click="changeProduct()"
          title="Сохранить"
        >
          Сохранить
        </b-button>
      </b-form>
      <div v-if="product">
        <interior-edit :product_id="product.id" :images_interior="product.images_interior"></interior-edit> 
        <colors-edit :product_id="product.id" v-if="product"></colors-edit>
        <configurations-edit :product_id="product.id" v-if="product"></configurations-edit>
        <carbodies-product-edit :product_id="product.id" v-if="product"></carbodies-product-edit>
      </div>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
      >{{ toast.message }}</b-toast
    >
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import ColorsEdit from "@/components/components/ColorsEdit.vue";
import ConfigurationsEdit from "@/components/components/ConfigurationsEdit.vue";
import Editor from "@/components/components/Editor.vue";
import InteriorEdit from "@/components/components/InteriorEdit.vue";
import CarbodiesProductEdit  from "@/components/components/CarbodiesProductEdit.vue";
export default {
  data() {
    return {
      product: null,
      categories:[],
      max_field_sort: 1,
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",        
      },
    };
  },
  created() {
    this.getAllcategories();
    this.getProduct();
    this.getMaxFieldSort();
  },
  components:{
    'colors-edit':ColorsEdit,
    'editor':Editor,
    'configurations-edit': ConfigurationsEdit,
    'interior-edit': InteriorEdit,
    'carbodies-product-edit': CarbodiesProductEdit
  },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.getProduct();
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    async getAllcategories() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/categories",
        "get"
      );
      if (data) {
        this.categories = data.map(el=>{ return {value: el.id, text: el.name}});
      }
    },
    async getMaxFieldSort() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/getmaxfieldsort",
        "get"
      );
      if (data) {
        this.max_field_sort = data;
      }
    },
    async getProduct() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/product",
        "post",
        { id: this.$route.params.id }
      );
      if (data) {
        this.product = data;
      }
    },
    async changeProduct() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changeproduct",
        "post",
        {...this.product}
      );
      this.showToast(data);
    },
  },
};
</script>
<style lang="scss">
</style>