<template>
    <div>
        <hr>
        <h4>Цвета</h4>
        <hr>
        <b-form class=" mb-2 ml-3">
        <b-input-group prepend="Название">
          <b-form-input
            type="text"
            label="Name"
            v-model="newcolor.name"
            required
            placeholder="Название"
          ></b-form-input>
        </b-input-group>  
        <b-input-group prepend="Цвет" class="mt-3">
          <b-form-input
            type="color"
            label="Color"
            v-model="newcolor.color"
            required
            placeholder="Цвет"
          ></b-form-input>
        </b-input-group>              
        <b-input-group class="mt-3" prepend="Изображения">
          <b-form-file
            placeholder="Изображения" 
            multiple 
            ref="files"
            @change="handleFileUpload"
          ></b-form-file>
        </b-input-group>
        <b-button
          class="my-3"
          variant="secondary"
          @click="addColor()"
          title="Добавить цвет"
        >
          Добавить цвет
        </b-button>
      </b-form>  
      <hr>
      <div class="ml-3">
        <color-edit  v-on:update-colors="getColors" :color="color" v-for="color in colors" :key="color.id" v-on:delete-color="deleteColor" class="mb-2"></color-edit>
      </div>      
    </div>
  </template>
  <script>
  import Authentication from "@/components/pages/Authentication";
  import ColorEdit from "@/components/components/ColorEdit";
  
  export default {
    props: ["product_id"],
    data() {
      return {
        colors: [],
        newcolor: {
            name: "",
            color: "#ffffff",           
            new_images:"",            
        },
      };
    },
    components:{
        'color-edit':ColorEdit,
    },
    created() {
      this.getColors();
    },
    methods: {         
      async getColors() {
        let { data } = await Authentication.sendRequestNotAuth(
          this,
          "/api/v1/colors?product_id="+this.product_id,
          "get",
        );
        if (data) {
          this.colors = data
        }
      },
      async addColor() {
        let { data } = await Authentication.sendRequest(
            this,
            "/api/v1/addcolor",
            "post",
            {...this.newcolor, product_id: this.product_id}
        );  
        if(data.success){
            this.colors.push(data.color)
            this.newcolor= {
                name: "",
                color: "#ffffff",           
                new_images:"",            
            }
            this.$refs['files'].reset()
        }
      },
      async deleteColor(id) {
        let { data } = await Authentication.sendRequest(
            this,
            "/api/v1/deletecolor/"+id,
            "post"
        );  
        if(data.success){
            this.colors = this.colors.filter(color=>color.id!=id)  

        }
      },
      handleFileUpload(event) {
        if (event.target.files.length > 0) {
          if(event.target.files.length<=10){
            this.newcolor.new_images = event.target.files;
          }else{
            alert('За раз можно добавить максимум 10 изображений')
          }
        } else {
          this.newcolor.new_images = "";
        }
      },
    },
  };
  </script>
  <style lang="scss">
  </style>