<template>
      <div class="bg-main-slider pt-2 mt-5">
        <b-container v-if="stockproduct">
          <div  class="mt-3 mt-md-5">
            <b-row align-v="center" align-h="center">
                <b-col cols="12" md="10" lg="6" class="mb-5 order-2 order-lg-1">
                    <h5 class="mt-3"><b>{{stockproduct.name}}</b></h5>
                    <h3 v-html="stockproduct.description_main_page" class="ql-editor ql-viewer"></h3>    
                    <b-row class="mt-5">
                        <b-col>
                            <a :href="'/stockproducts/'+ stockproduct.id" class="more_inforamtion">Подробнее</a>                            
                        </b-col>   
                        <b-col class="pl-0" v-if="stockproduct.price">
                            <div class="price-slider mt-2">
                                <span style="white-space: nowrap;"><b>{{ pricefromproduct }} </b><span class="ql-editor  ql-viewer ql-inline" v-html="settings.valutastock"></span></span>
                            </div>                            
                        </b-col>
                    </b-row>                  
                </b-col>
                <b-col md="12" lg="6" class="order-1 order-lg-2 mb-3">
                  <h3 class="mb-4 text-center"><a href="/stockproducts"><b> <span v-html="settings.bannercaption"></span></b></a></h3>
                    <a :href="'/stockproducts/'+ stockproduct.id"><img :src="'/'+imagesfromstockproduct.path" width="100%" class="shadow-green"/></a>                    
                </b-col>
            </b-row>
                
                
          </div>
        </b-container>  
      </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
export default {
  props:['settings'],
    data() {
    return {
      stockproduct: null,
    };
  },
  created() {
    this.getStockproductsMainPage();
  },
  computed:{
    imagesfromstockproduct(){      
      let images_main = this.stockproduct.images.filter(img=>img.main)
      if(images_main.length>0){
        return images_main[0]
      }else{
        return this.stockproduct.images[0]
      }
       
    },
    pricefromproduct(){
      if(!this.stockproduct.price){
        return 0
      }
      return  this.stockproduct.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
  },
  methods: {
  async getStockproductsMainPage() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/stockproducts?main=true&public=true",
        "get"
      );
      if (data) {
        this.stockproduct = data[0]
      }
    },
}

};
</script>
<style lang="scss">
    .bg-main-slider{
        //background-image: url("~@/assets/images/main-bg1.jpg");
        background: radial-gradient(#ffffff, #797979);
        background-size: cover;
        background-position: center;  
        padding-bottom: 50px;  
        img.shadow-green{
          border-radius: 40px;
            transition: 0.1s;
            &:hover{
                box-shadow: rgb(37 211 102) 0px 0px 3px;
            }
        }    
    }
    .more_inforamtion{
        text-decoration: none;
        font-weight: 600;
        color: #ffffff;
        padding: 15px 20px;
        display: block;
        background-color: $color1;
        text-align: center;
        &:hover{
            text-decoration: none;
            background-color: $color2;
            color: #fff;

        }
    }
    .price-slider{
        text-align: center;
        font-size: 30px;
    }
    @media (max-width: 1024px) {
        .bg-main-slider.top-header{ 
            img{
              border-radius: 0;
            }
        }
    }
    @media (max-width: 767px) {
        .bg-main-slider.top-header{
            padding-bottom: 30px;  
            padding-top: 65px; 
        }
        .price-slider{
          font-size: 24px;
         }
  }

.animate {
  transition-delay: .5s;
  transition-duration: 0.8s;
  transition-timing-function: ease;
}

.slide-scale {
  transform: scale(0.5);
  opacity: 0;
}

.slide-scale.animate-active {
  transform: scale(1);
  opacity: 1;
}

.slide-left {
  transform: translateX(100px);
  opacity: 0;
}

.slide-left.animate-active {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 767px) {
  .slide-left {
    transform: translateX(0);
    opacity: 0;
  }

}


.home-infoblock1-text{
  padding: 20px;
  background: radial-gradient(#ffffff, #797979);
  border-radius: 40px;
  border-radius: 15px;
}
@media (max-width: 1024px) {
    .home-infoblock1-text{
        border-radius: 0;

    }


}  

</style>