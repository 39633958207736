<template>
  <div class="page-admin-block">
    <b-container>
      <h3>Контакты</h3>
      <hr>
      <page-editor :alias="'contacts'"></page-editor>
      <hr>
      <div class="ml-3">
      <h5 class="mt-1">Добавить контакт</h5>
            <b-row>
          <b-col lg="8" md="12">
            <b-form>        
              <b-input-group class="my-1">
                <b-form-input
                  type="text"
                  label="name"
                  v-model="newcontact.name"
                  required
                  placeholder="Имя"
                ></b-form-input>
              </b-input-group>      
              <b-input-group class="my-1">
                <b-form-textarea
              v-model="newcontact.description"
              :maxlength="4000"
              placeholder="Описание"
              rows="4"
            ></b-form-textarea>                
              </b-input-group>
              <b-input-group class="my-1">
                <b-form-textarea
              v-model="newcontact.slogan"
              :maxlength="4000"
              placeholder="Слоган"
              rows="2"
            ></b-form-textarea>                
              </b-input-group>                           
      </b-form>
    </b-col>
    <b-col lg="4" md="6">
      <b-input-group class="my-1">
                <b-form-input
                  type="text"
                  label="e_mail"
                  v-model="newcontact.e_mail"
                  required
                  placeholder="Почта"
                ></b-form-input>
              </b-input-group>
      <b-input-group  class="my-1">
                <b-form-file
                  placeholder="Фото"
                  ref="files"
                  @change="handleFileUpload"
                ></b-form-file>
              </b-input-group>       
        <img :src="imagesrc" v-if="imagesrc" width="100%">
        <b-button variant="success" block @click="addcontact()" title="Добавить контакт">
          <b-icon icon="plus"></b-icon>
        </b-button>
    </b-col>
        </b-row>
      </div>
      <hr>
      <div class="ml-3">
      <h5 class="mt-1">Список контактов</h5>
        <b-row align-v="stretch"  align-h="center" justify-content-center class="mt-3">
            <b-col cols="12" md="12" lg="6" v-for="contact in contacts" :key="contact.id" class="mb-4">
                <card-contact :contact="contact" v-on:show-toast="showToast"></card-contact>
              </b-col>
          </b-row>
        </div>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
    >{{ toast.message }}</b-toast>
  </div>
</template>
<script>
import PageEditor from "@/components/components/PageEditor";
import Authentication from "@/components/pages/Authentication";
import CardContact from '@/components/components/CardContact';

export default {
  data() {
    return {
      contacts: [],
      imagesrc: null,
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      newcontact: {
        name: "",
        slogan: "",
        description: "",
        e_mail: "",
        new_images:"",
      },
    };
  },
  components: {
    'page-editor': PageEditor,
    'card-contact':CardContact
  },  
  created() {
    this.getAllcontacts();
  },
  methods: {
    showToast(data, update = true) {
      if (data) {
        if (data.success) {
          if(update){
            this.getAllcontacts();
          }
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    async getAllcontacts() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/contacts",
        "get"
      );
      if (data) {
        this.contacts = data;
      }
    },
    async addcontact() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/addcontact",
        "post",
        this.newcontact
      );
      this.showToast(data);

      this.newcontact = {
        name: "",
        slogan: "",
        description: "",
        e_mail: "",
        new_images:"",
      };
      this.$refs['files'].reset()
      this.imagesrc = null
    },
    handleFileUpload(event){
        if(event.target.files.length>0){
            this.newcontact.new_images = event.target.files;   
            var reader = new FileReader();
            var vm = this;
            reader.onload = function(e) {
              vm.imagesrc = e.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
        }else{
            this.newcontact.new_images =''
            this.imagesrc = null
        }
        
    },    
  }
};
</script>
<style lang="scss">
</style>