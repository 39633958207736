<template>
    <div>
        <hr>
        <h4>Кузов</h4>
        <hr>
        <b-form class=" mb-2 ml-3">
          <b-form-checkbox v-for="carbody in carbodies" v-model="carbody.check" :key="carbody.id" @change="changeCarbodyProduct(carbody)">
            {{ carbody.name }}
          </b-form-checkbox>              
      </b-form> 
      <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
    >{{ toast.message }}</b-toast>      
    </div>
  </template>
  <script>
  import Authentication from "@/components/pages/Authentication";
  
  export default {
    props: ["product_id"],
    data() {
      return {
        carbodies:[],
        toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      };
    },
    mounted() {
      this.getAllСarbodies();
    },
    methods: {  
      showToast(data) {
      if (data) {
        if (data.success) {
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
      async getAllСarbodies() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/carbodiesproduct/"+this.product_id,
        "get"
      );
      console.log(data)
      if (data) {
        this.carbodies = data;
      }
    },    
      
      async changeCarbodyProduct(carbody){
        let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changecarbodyproduct",
        "post",
        {carbody: carbody, product_id: this.product_id }
      );
        this.showToast(data);      
      }
    },
  };
  </script>
  <style lang="scss">
  </style>