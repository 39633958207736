<template>
  <div class="top-header">
      <b-container class="mt-5">  
        <h2>
          <b>
            <span v-if="settings" v-html="settings.stockcaption"></span>
          </b>
        </h2> 
        <b-row>
          <b-col>
             <b-row class="catalog-products">
        <b-col
          cols="12"
          md="4"
          lg="3"
          
          v-for="stockproduct in stockproducts"
          :key="stockproduct.id"
        >
          <card-stockproduct :class="{'gray-stockproduct':($route.params.id && $route.params.id!=stockproduct.id)}"
          :settings="settings"
            :stockproduct="stockproduct"
            :admin="false"
          ></card-stockproduct>
        </b-col>
      </b-row>
      <div class="text-center mb-3"> 
        <b-button v-if="showmorebutton" @click="Showmore()">Показать еще</b-button>
      </div>
      <mail-form-discount class="my-5"></mail-form-discount>
          </b-col>
        </b-row>  
              
      
      </b-container>      
      
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
import CardStockProduct from '@/components/components/CardStockProduct';
import MailFormDiscount from "@/components/components/MailFormDiscount";
export default {
  props:['settings'],
    data() {
    return {
      stockproducts:[], 
      page:1,
      count:8,
      showmorebutton:true,
    };
  },
    components:{        
        'card-stockproduct':CardStockProduct,
        'mail-form-discount': MailFormDiscount,
    },
    computed:{
    },
  created() {
    this.getStockproducts()
  },
  methods: {    
    Showmore(){
        this.page++
        this.getStockproducts()
    },
    async getStockproducts() {       
      let url = "/api/v1/stockproducts?page="+this.page+"&count="+this.count+'&public=true'
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        url,
        "get",
      );
      if (data) {
        if(data.length<this.count){
            this.showmorebutton=false
        }
        this.stockproducts.push(...data);
      }
    },  
  },
};
</script>
<style lang="scss">    
.catalog-stockproducts{
  .card-stockproduct {
    padding-bottom: 20px;
  }
}
    .search-form{
        background-color: $color3;
        color:#fff;
    }
    .price-bottom{
      padding-bottom: 60px;
    }
    .categories{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .categories-block{
      max-height: calc(100vh - 380px);
      overflow-y: auto;
      margin-bottom: 20px;
    }
    .category{
      border-top: 1px solid #ccc;
      cursor: pointer;
      &:hover, &.active{
        background-color: $color1;
        color: #fff;
        font-weight: 600;
      }
    }
    .custom-select{
      border-radius: 0;
    }
    .categories-block::-webkit-scrollbar {
      width: 7px;
    }

    .categories-block::-webkit-scrollbar-track {
      background-color: #e4e4e4;
    }

    .categories-block::-webkit-scrollbar-thumb {
      background-color: rgba($color1,0.5);
    }
@media (min-width: 768px) {
    .sticky-filter{
      position:-webkit-sticky; 
      position:sticky;
      top:10px;
    }
    }
</style>