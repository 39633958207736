<template>
  <div>    
    <hr>
        <h4>Фото салона</h4>
        <hr>
      <div class="border p-2 ml-3" v-if="images_interior_edit">                     
        <b-input-group class="mt-1" prepend="Изображения">
          <b-form-file
            placeholder="Изображения" 
            multiple 
            ref="files"
            @change="handleFileUpload"
          ></b-form-file>
        </b-input-group>
        <b-button
          class="mt-3"
          variant="secondary"
          @click="addimagesInterior()"
          title="Сохранить"
        >
          Сохранить
        </b-button>

        <div>
          <div
            v-for="image in images_interior_edit"
            class="edit-image"
            :key="image.id"
          >
             <button @click="deleteImage(image.id)" class="btn btn-danger" title="удалить"><b-icon icon="X" aria-hidden="true"></b-icon></button> 
            <img :src="'/'+image.path" />
          </div>
        </div>        
      </div>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
      >{{ toast.message }}</b-toast
    >
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
export default {
  props:['product_id','images_interior'],
  data() {
    return {
      images_interior_edit: this.images_interior,
      new_images:"",
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
    };
  },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },  
    async addimagesInterior() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/addinterior",
        "post",
        {new_images: this.new_images, product_id: this.product_id}
      );
      if(data.success){
        this.images_interior_edit.push(...data.images_interior)
      }
      this.showToast(data);
      this.$refs['files'].reset()
    },
    handleFileUpload(event) {
        if (event.target.files.length > 0) {
          if(event.target.files.length<=10){
            this.new_images = event.target.files;
          }else{
            alert('За раз можно добавить максимум 10 изображений')
          }
        } else {
          this.new_images = "";
        }
      },
    async deleteImage(id){
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/deleteinterior",
        "post",
        {id: id}
      );
      if(data.success){
        this.images_interior_edit= this.images_interior_edit.filter(el=>el.id!=id)
      }
      this.showToast(data);
      
    },
  },
};
</script>
<style lang="scss">
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>