<template>
    <div class="border bg-white position-relative" style="height: 100%;">      
        <div class="p-2 pb-5">
          <b-row>
            <b-col lg="8" md="8">
               <b-input-group class="mt-2 mb-2">
            <b-form-input
                  type="text"
                  label="Name"
                  v-model="contact.name"
                  required
                  placeholder="Имя"
                ></b-form-input>
              </b-input-group>
              <b-input-group class="my-1">
                <b-form-textarea
              v-model="contact.description"
              :maxlength="4000"
              placeholder="Описание"
              rows="4"
            ></b-form-textarea>                
              </b-input-group>
              <b-input-group class="my-1">
                <b-form-textarea
              v-model="contact.slogan"
              :maxlength="4000"
              placeholder="Слоган"
              rows="2"
            ></b-form-textarea>                
              </b-input-group>      
              <b-input-group class="my-1">
                <b-form-input
                  type="text"
                  label="e_mail"
                  v-model="contact.e_mail"
                  required
                  placeholder="Почта"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
               <b-input-group class="mt-2 mb-2">
            <b-form-file 
              placeholder="Фото"
              ref="filesedit"
              @change="handleFileUpload"
              ></b-form-file>
              </b-input-group>
        <div class="pt-3 pb-5">
          <div class="circle-image">
            <img :src="(imagesrc ? imagesrc:'/'+contact.photo.path)"/>
          </div>
        </div> 

            </b-col>
          </b-row>

             
         
        
        </div> 
        
              
              <div class="position-absolute" style="bottom: 0; width: 100%;">
                <b-row no-gutters>
                <b-col cols="6">
                    <b-button @click="changecontact(contact)" variant="secondary" block title="Сохранить изменения">
                    <b-icon icon="pencil"></b-icon>
                  </b-button>
                </b-col>
                <b-col  cols="6">
                    <b-button variant="danger" @click="deletecontact(contact)" block title="Удалить контакт">
                    <b-icon icon="trash"></b-icon>
              </b-button>
                </b-col>
              </b-row>
              </div>             
              
                  
            </div>      
  </template>
  <script>
  import Authentication from "@/components/pages/Authentication";
  export default {
    props:['contact'],
    data() {
      return {
          new_images:"",
          imagesrc: null,
      };
    },
    methods: {
      async deletecontact(contact) {
        let { data } = await Authentication.sendRequest(
          this,
          "/api/v1/deletecontact/"+contact.id,
          "post",
        );
        this.$emit('show-toast', data)
      },
      async changecontact(contact) {
        let { data } = await Authentication.sendRequest(
          this,
          "/api/v1/changecontact",
          "post",
          { ...contact, new_images: this.new_images }
        );
        if(data.success){
          this.$refs['filesedit'].reset()
        }
        this.$emit('show-toast', data,false)            
      },
      handleFileUpload(event){
          if(event.target.files.length>0){
              this.new_images = event.target.files;
              var reader = new FileReader();
            var vm = this;
            reader.onload = function(e) {
              vm.imagesrc = e.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
          }else{
              this.new_images =''
              this.imagesrc = null
          }
          
      },    
    },
  };
  </script>
  <style lang="scss">
  </style>