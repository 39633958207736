<template>
    <div>
        <hr>
        <h4>Комплектация</h4>
        <hr>
        <b-form class=" mb-2 ml-3">
          <b-input-group prepend="Название">
          <b-form-input
            type="text"
            label="Name"
            v-model="newconfiguration.name"
            required
            placeholder="Название"
          ></b-form-input>
        </b-input-group>   
        <b-form-group label="Описание"  class="mt-2">
          <editor v-model="newconfiguration.description"></editor>
        </b-form-group>
        <b-input-group prepend="Цена">
          <b-form-input
            type="text"
            label="Price"
            v-model="newconfiguration.price"
            required
            placeholder="Цена"
          ></b-form-input>
        </b-input-group>                    
        <b-button
          class="my-3"
          variant="secondary"
          @click="addconfiguration()"
          title="Добавить комплектацию"
        >
          Добавить комплектацию
        </b-button>
      </b-form>  
      <hr>
      <div class="ml-3">
        <configuration-edit :configuration="configuration" v-for="configuration in configurations" :key="configuration.id" v-on:delete-configuration="deleteconfiguration" class="mb-2"></configuration-edit>
      </div>      
    </div>
  </template>
  <script>
  import Authentication from "@/components/pages/Authentication";
  import ConfigurationEdit from "@/components/components/ConfigurationEdit";
  import Editor from "@/components/components/Editor.vue";
  
  export default {
    props: ["product_id"],
    data() {
      return {
        configurations: [],
        newconfiguration: {
            name: "",
            description:"",
            full_description:"",
            price: 0,                      
        },
      };
    },
    components:{
        'configuration-edit':ConfigurationEdit,
        'editor': Editor
    },
    created() {
      this.getconfigurations();
    },
    methods: {          
      async getconfigurations() {
        let { data } = await Authentication.sendRequestNotAuth(
          this,
          "/api/v1/configurations?product_id="+this.product_id,
          "get",
        );
        if (data) {
          this.configurations = data;
        }
      },
      async addconfiguration() {
        let { data } = await Authentication.sendRequest(
            this,
            "/api/v1/addconfiguration",
            "post",
            {...this.newconfiguration, product_id: this.product_id}
        );  
        if(data.success){
            this.configurations.push(data.configuration)
            this.newconfiguration= {
                name: "",
                description:"",
                full_description:"",
                price: 0,            
            }
        }
      },
      async deleteconfiguration(id) {
        let { data } = await Authentication.sendRequest(
            this,
            "/api/v1/deleteconfiguration/"+id,
            "post"
        );  
        if(data.success){
            this.configurations = this.configurations.filter(configuration=>configuration.id!=id)  

        }
      },
      handleFileUpload(event) {
        if (event.target.files.length > 0) {
          this.newconfiguration.new_images = event.target.files;
        } else {
          this.newconfiguration.new_images = "";
        }
      },
    },
  };
  </script>
  <style lang="scss">
  </style>