<template>
  <div id="app" :class="{admin : $router.currentRoute.meta.header=='admin'}">
      <transition name="fade">
        <loader-page v-show="loadpage"></loader-page>
      </transition>
      <header-admin :pages="pages" v-if="$router.currentRoute.meta.header=='admin'"></header-admin>
      <app-header :pages="pages" :settings="settings" v-if="$router.currentRoute.meta.header=='default'"></app-header>
      <div class="content" v-if="settings">
          <router-view :settings="settings"/>
      </div>      
      <app-footer :settings="settings" v-if="$router.currentRoute.meta.header=='default'"></app-footer>
  </div>
</template>
<script>
import Axios from 'axios'
import Authentication from "@/components/pages/Authentication";
import Header from '@/components/components/Header'
import Footer from '@/components/components/Footer'
import HeaderAdmin from '@/components/components/HeaderAdmin'
import LoaderPage from '@/components/components/LoaderPage'
  export default {
    data() {
    return {
      loadpage:true,
      pages:[],
      settings:null
    };
  },
    name: 'app',
    components:{
        'app-header': Header,
        'header-admin': HeaderAdmin,
        'app-footer':Footer,
        'loader-page':LoaderPage
    },
    created() {
        this.getPages() 
        this.getSettings()
        
        
    window.addEventListener("load",() => {
            this.loadpage = false
    }          
    )
    
  },
  methods: {    
    async getCours(valuta){
      if(!this.$cookie.get(valuta)){
        let {data} = await Axios.get(`https://www.cbr-xml-daily.ru/latest.js`)
        if(data.rates){
          this.$cookie.set(valuta, data.rates[valuta],  { expires: '12h' })
        } 
      }
    },
    async getPages() {       
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/pages",
        "post",
      );
      if (data) {
        this.pages = data;
      }
    }, 
    async getSettings() {       
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/getglobalsettings",
        "get",
      );
      if (data) {
        this.settings = data;
        this.getCours(this.settings.currency_type)
      }
    },   
  },
  }
</script>
<style lang="scss">
@import "@/assets/styles";
body{
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    min-height: 100vh;
    position: relative;
    background-color: #f0f0f0;
    font-display: swap;
}
.content{
    min-height: 90vh;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}

</style>