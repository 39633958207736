<template>
  <div v-if="color_edit">
    <b-row class="mb-2" no-gutters>
      <b-col cols="11" md="6">
        <div class="bg-white p-1 position-relative border" v-b-toggle="'collapse-'+color_edit.id"> 
          <div style="width: 28px; height: 28px;" :style="{backgroundColor: color_edit.color}" class="border p-1 d-inline-block align-middle"></div>
          <div class="d-inline-block align-middle mx-2">{{color_edit.name}}</div>
          <span class="when-opened float-right" style="font-size: 20px;">
              <b-icon icon="x" aria-hidden="true"></b-icon>            
            </span>
            <span class="when-closed float-right" style="font-size: 20px;">
              <b-icon icon="arrow-down-short" aria-hidden="true"></b-icon>           
            </span>                
        </div>
      </b-col>
      <b-col>
        <b-button @click="deleteColor()" class="btn btn-danger" title="удалить">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>
      </b-col>
    </b-row>
    <b-collapse :id="'collapse-'+color_edit.id"> 
      <b-form class="border p-2">
        <b-row>
          <b-col cols="12" md="6">
            <b-input-group prepend="Название" class="mb-3">
          <b-form-input
            id="color_name"
            type="text"
            label="Name"
            v-model="color_edit.name"
            required
            placeholder="Название"
          ></b-form-input>
        </b-input-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-input-group prepend="Цвет">
          <b-form-input
            type="color"
            label="Color"
            v-model="color_edit.color"
            required
            placeholder="Цвет"
          ></b-form-input>
        </b-input-group> 
          </b-col>
        </b-row>        
        <b-input-group class="mt-3" prepend="Изображения">
          <b-form-file
            placeholder="Изображения" 
            multiple 
            ref="files"
            @change="handleFileUpload"
          ></b-form-file>
        </b-input-group>        
        
        <div>
          <div
            v-for="image in color_edit.images"
            class="edit-image"
            :key="image.id"
          >
             <button @click="deleteImage(image.id)" class="btn btn-danger" title="удалить"><b-icon icon="X" aria-hidden="true"></b-icon></button> 
            <img :src="'/'+image.path" />
            <button @click="setMain(image.id)" class="btn btn-secondary" type="button" v-if="!image.main">Сделать главной</button>
          </div>
        </div>
        <b-button
          class="mt-3"
          variant="secondary"
          @click="changecolor()"
          title="Сохранить"
        >
          Сохранить
        </b-button>
      </b-form>
    </b-collapse> 
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
      >{{ toast.message }}</b-toast
    >
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
export default {
  props:['color'],
  data() {
    return {
      color_edit: this.color,
      new_images:"",
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
    };
  },
  watch: { 
    color: function(newVal) {
      this.$set(this.color_edit,'images', newVal.images) 
     }
    },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.getcolor();
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    }, 
    async getcolor() {
      let { data } = await Authentication.sendRequestNotAuth(
        this,
        "/api/v1/color/"+this.color_edit.id,
        "get",
      );
      if (data) {
        this.color_edit = data;
      }
    },
    async changecolor() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changecolor",
        "post",
        {new_images: this.new_images, ...this.color_edit}
      );
      this.showToast(data);
      this.$refs['files'].reset()
    },
    handleFileUpload(event) {
        if (event.target.files.length > 0) {
          if(event.target.files.length<=10){
            this.new_images = event.target.files;
          }else{
            alert('За раз можно добавить максимум 10 изображений')
          }
        } else {
          this.new_images = "";
        }
      },
    async setMain(id){
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changemainimagecolor",
        "post",
        {id: id, product_id: this.color_edit.product_id}
      );
      if(data.success){
        this.$emit('update-colors')
      }
    },
    deleteImage(id){
        let images = this.color_edit.images;
        this.color_edit.images=images.filter(el=>el.id!=id)
    },
    deleteColor(){
      this.$emit('delete-color', this.color_edit.id)
    }
  },
};
</script>
<style lang="scss">
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>