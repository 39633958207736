<template>
  <div class="page-admin-block">
    <b-container class="py-4">
      <h3>Кузовы</h3>   
       <hr>
       <div class="ml-3">       
      <h5 class="mt-2">Добавить кузов</h5>
      <b-form inline>
        <b-input-group>
          <b-form-input
            type="text"
            label="name"
            v-model="newcarbody.name"
            required
            placeholder="название"
          ></b-form-input>
        </b-input-group>
        <b-button variant="secondary" @click="addСarbody()" title="Добавить кузов">
          <b-icon icon="plus"></b-icon>
        </b-button>
      </b-form>
      </div>
      <hr>
      <div class="ml-3">
      <h5>Список кузовов</h5>   
      <div v-for="carbody in carbodies" :key="carbody.id">
        <b-row align-v="center" >
          <b-col cols="9" md="6" lg="4">
            <b-input-group class="mt-2 mb-2">
              <b-form-input
                type="text"
                label="Password"
                v-model="carbody.name"
                required
                placeholder="Пароль"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="changeCarbody(carbody)" title="Изменить кузов">
                  <b-icon icon="pencil"></b-icon>
                </b-button>
                <b-button @click="deleteСarbody(carbody)" title="Удалить кузов">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      </div>
      <hr>
    </b-container>
    <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
    >{{ toast.message }}</b-toast>
  </div>
</template>
<script>
import Authentication from "@/components/pages/Authentication";
export default {
  data() {
    return {
      carbodies: [],
      toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      newcarbody: {
        name: "",
      },
    };
  },
  created() {
    this.getAllСarbodies();
  },
  methods: {
    showToast(data) {
      if (data) {
        if (data.success) {
          this.getAllСarbodies();
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
    async getAllСarbodies() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/carbodies",
        "get"
      );
      if (data) {
        this.carbodies = data;
      }
    },
    async addСarbody() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/addcarbody",
        "post",
        this.newcarbody
      );
      this.showToast(data);

      this.newcarbody = {
        name: "",
      };
    },
    async deleteСarbody(carbody) {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/deletecarbody/"+carbody.id,
        "post",
      );
      this.showToast(data);
    },
    async changeCarbody(carbody) {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changecarbody",
        "post",
        { id: carbody.id, name: carbody.name }
      );
      this.showToast(data);
    },
  },
};
</script>
<style lang="scss">
</style>